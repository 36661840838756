<template>
	<h1>Logging out</h1>
</template>

<script>
export default {
	name: "Logout",

	mounted() {
		//alert('mounted');

		//delete localStorage.token

		this.$store.dispatch("setDestination", "/conferences");

		this.$store.dispatch("logout");
		this.$router.push("/welcome");
	}
};
</script>
